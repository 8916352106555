<template>
    <div v-if="false">
        <div class="menu-left-heading">
            <span>Floors</span>
        </div>

        <div class="Accordion">
            <expander
                :loading="false"
                v-for="(floor, floorI) in floors"
                :key="floor.id + floorI"
                :title="floor.name"
                animation="bounceIn"
                :open="$store.state.condogrid.currentFloor === floor.id"
                @clicked="changeFloor(floor)"
                :show-edit="true"
                @edit="editFloor(floor)"
            >
                <vue-custom-scrollbar
                    style="max-height: 300px; min-height: 100px"
                    class="py-3"
                >
                    <template v-if="Object.values(lots).length">
                        <expander
                            :loading="false"
                            v-for="(lot, lotI) in Object.values(lots)"
                            :key="lot.id + lotI"
                            :title="lot.name"
                            animation="bounceIn"
                            @clicked="
                                $store.commit('SELECT_LOT_SHAPE', lot.shapeId)
                            "
                            :show-edit="true"
                            @edit="editLot(lot)"
                            :open="openID === lot.id"
                            :class="
                                $store.state.condogrid.currentShape ===
                                lot.shapeId
                                    ? 'text-primary'
                                    : ''
                            "
                            :arrow="false"
                            :border-style="false"
                        />
                    </template>
                    <template v-else>
                        <div class="text-center px-4">
                            <h5>You have no Lots created.</h5>
                            Please use the
                            <svg
                                width="24px"
                                height="24px"
                                viewport="0 0 24 24"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <polygon
                                    class="nofill"
                                    fill="none"
                                    style="stroke: #333; stroke-width: 2px"
                                    points="20,20 18,4 7,7 4,20"
                                ></polygon>
                                <ellipse
                                    fill="#333"
                                    cx="20"
                                    cy="20"
                                    rx="3"
                                    ry="3"
                                ></ellipse>
                                <ellipse
                                    fill="#333"
                                    cx="18"
                                    cy="4"
                                    rx="3"
                                    ry="3"
                                ></ellipse>
                                <ellipse
                                    fill="#333"
                                    cx="7"
                                    cy="7"
                                    rx="3"
                                    ry="3"
                                ></ellipse>
                                <ellipse
                                    fill="#333"
                                    cx="4"
                                    cy="20"
                                    rx="3"
                                    ry="3"
                                ></ellipse>
                            </svg>
                            Tool to draw lots.
                        </div>
                    </template>
                </vue-custom-scrollbar>
            </expander>
        </div>
    </div>
    <div v-else>
        <LeftNavBar
            :openNavCondogrid="currentShape"
            :highlightObj="highlightObj"
            @bulkDeleteSplit="bulkDeleteSplit"
            :splitSecondNav="true"
            :bulkSelect="true"
            :selectedValue="$store.state.condogrid.currentType"
            :accordion="true"
            :items="floorList"
            :title="selectedAppInitial"
            @opened="parentClicked"
            @accordion="childClicked"
            @optionclick="optionClick"
            @chosen="grandChildClicked"
        >
        </LeftNavBar>
    </div>
</template>

<script>
import Expander from "bh-mod/components/common/Expander";
import vueCustomScrollbar from "vue-custom-scrollbar";
import LeftNavBar from "bh-mod/components/common/LeftNavBar";
export default {
    components: { Expander, vueCustomScrollbar, LeftNavBar },
    name: "menu-left",
    data() {
        return {
            openID: "",
            floorToEdit: "",
            highlightObj: {},
            openNav: {},
        };
    },
    computed: {
		selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "CGC"
            );
        },
        currentShape() {
            return this.$store.state.condogrid.currentShape;
        },
        instance() {
            return this.$store.state.instance;
        },
        lots() {
            return this.$store.getters.lots;
        },
        folders() {
            return this.$store.getters.folders;
        },
        files() {
            return this.$store.getters.allFiles;
        },
        floors() {
            return this.$store.state.condogrid.floors || [];
        },
        floorList() {
            let list = [
                {
                    label: "Condo Floors",
                    value: "floors",
					iconUrl: require("@/assets/sideIcons/floors.svg"),
                    options: [],
                    children: [],
                }
            ];
            let floors = Object.values(this.$store.state.condogrid.floors);

            floors.forEach((floor) => {
                let child = {};
                child.label = floor.name;
                child.value = floor.id;
				child.iconUrl = require("@/assets/sideIcons/floors.svg"),
                child.parent = "floors";
                list[0].children.push(child);
                child.hasGrandChild = true;
                child.children = [];
                let unitList = [];
                let amenityList = [];
                child.split = [
                    {
                        name: "Units",
                        allSelect: false,
                        empty: "You have no Units on this floor...",
                    },
                    {
                        name: "Amenities",
                        allSelect: false,
                        empty: "You have no Amenities on this floor...",
                    },
                ];
                floor.siteplan.lots.forEach((x) => {
                    let unit = {};
                    let storeUnit = Object.values(
                        this.$store.state.condogrid.units
                    );
                    if (x.type != "info") {
                        let found;
                        if (typeof x.units[0] == "string") {
                            found = storeUnit.find(
                                (unit) => unit.id == x.units[0]
                            );
                        } else {
                            found = x.units[0];
                        }
                        unit.label = found && found.unitNumber ? `${found.unitNumber}${found.name ? ' - ' + found.name : ''}` : 'Unit does not exist'
                        unit.value = x.id;
                        unit.parent = floor.name;
                        unit.type = "Units";

                        unit.options = [
                            { label: "Edit", value: "edit" },
                            { label: "Delete", value: "delete" },
                        ];
                        unitList.push(unit);
                    } else if (x.type == "info") {
                        let amenity = {};
                        amenity.label = x.name;
                        amenity.type = "Amenities";
                        amenity.value = x.id;
                        amenity.parent = floor.name;
                        amenity.options = [
                            { label: `Edit ${x.name}`, value: "edit" },
                            { label: `Delete ${x.name}`, value: "delete" },
                        ];
                        amenityList.push(amenity);
                    }
                });
                child.children.push(unitList);
                child.children.push(amenityList);
            });

            list[0].children = list[0].children.sort((a, b) => {
                if (a.label > b.label) return 1;
                else if (a.label < b.label) return -1;
                else return 0;
            });

            return list;
        }
    },
    methods: {
        bulkDeleteSplit(array) {
            console.log("array", array);
        },
        updateAccordion(data) {
            if (data.value == "floors") {
                this.floorList[0] = {};
            }
            if (data.value == "amenities") {
                this.floorList[1] = {};
            }
        },
        grandChildClicked(item) {
            this.highlightObj = item;
            let crumbObj = {};
            Object.values(this.floors).forEach((floor) => {
                floor.siteplan.lots.forEach((lot) => {
                    if (lot.id == item.value) {
                        this.$store.commit("SELECT_LOT_SHAPE", lot.shapeId);
                        crumbObj = lot;
                    }
                });
            });
            let found = {};
            if (crumbObj.type != "info") {
                let storeUnit = Object.values(
                    this.$store.state.condogrid.units
                );
                if (typeof crumbObj.units[0] == "string") {
                    found = storeUnit.find(
                        (unit) => unit.id == crumbObj.units[0]
                    );
                } else {
                    found = crumbObj.units[0];
                }
            } else if (crumbObj.type == "info") {
                found = crumbObj;
            }
            let crumbName = "";
            if (found && found.type == "info") {
                crumbName = found.name;
            } else {
                crumbName = found && found.unitNumber ? `${found.unitNumber}${found.name ? ' - ' + found.name : ''}` : 'Unit does not exist'
            }
            this.$store.commit("SET_CRUMBS", crumbName);
        },
        optionClick(item) {
            if (
                item.optionValue == "edit" &&
                item.splitParent.name == "Units"
            ) {
                let editingLot = {};
                let floors = Object.values(this.floors);
                floors.forEach((floor) => {
                    let lots = floor.siteplan.lots.forEach((lot) => {
                        if (lot.id == item.itemValue) {
                            editingLot = lot;
                        }
                    });
                });
                this.$store.commit("SHOW_EDIT_LOT", editingLot);
            }

            if (
                item.optionValue == "edit" &&
                item.splitParent.name == "Amenities"
            ) {
                let editingAmenity = {};
                let floors = Object.values(this.floors);
                floors.forEach((floor) => {
                    let lots = floor.siteplan.lots.forEach((lot) => {
                        if (lot.id == item.itemValue) {
                            editingAmenity = lot;
                        }
                    });
                });
                this.$store.commit("SHOW_EDIT_AMENITY", editingAmenity);
            }

            if (
                item.optionValue == "delete" &&
                item.splitParent.name == "Amenities"
            ) {
                let self = this;
                this.$confirm({
                    title: "Delete Amenity",
                    content: (h) => (
                        <div>Do you want to delete this amenity?</div>
                    ),
                    okText: "Delete",
                    okType: "danger",
                    cancelText: "Cancel",
                    centered: true,
                    async onOk() {
                        let siteplanId;
                        Object.values(self.floors).forEach((floor) => {
                            floor.siteplan.lots.forEach((lot) => {
                                if (lot.id == item.itemValue) {
                                    siteplanId = floor.siteplan.id;
                                }
                            });
                        });

                        self.$api
                            .delete(
                                `/lots/${self.instance.id}/${siteplanId}/${item.itemValue}`
                            )
                            .then(({ data }) => {
                                self.$store.dispatch("CLOSE_DRAWER", {
                                    type: "delete",
                                    data,
                                });
                                // $.image_map_pro_init_editor(undefined, $.WCPEditorSettings);
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									self.$message.error(self.$err(err))
								}
							});
                    },
                    onCancel() {
                        console.log("Cancel");
                    },
                });
            }

            if (
                item.optionValue == "delete" &&
                item.splitParent.name == "Units"
            ) {
                let self = this;
                this.$confirm({
                    title: "Delete Unit",
                    content: (h) => <div>Do you want to delete this unit?</div>,
                    okText: "Delete",
                    okType: "danger",
                    cancelText: "Cancel",
                    centered: true,
                    async onOk() {
                        let siteplanId;
                        Object.values(self.floors).forEach((floor) => {
                            floor.siteplan.lots.forEach((lot) => {
                                if (lot.id == item.itemValue) {
                                    siteplanId = floor.siteplan.id;
                                }
                            });
                        });

                        self.$api
                            .delete(
                                `/lots/${self.instance.id}/${siteplanId}/${item.itemValue}`
                            )
                            .then(({ data }) => {
                                self.$store.dispatch("CLOSE_DRAWER", {
                                    type: "delete",
                                    data,
                                });
                                // $.image_map_pro_init_editor(undefined, $.WCPEditorSettings);
                            }).catch(err => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									self.$message.error(self.$err(err))
								}
							});
                    },
                    onCancel() {
                        console.log("Cancel");
                    },
                });
            }
        },
        parentClicked(item) {
            console.log("item", item);
        },
        childClicked(item) {
            if (item.label != this.$store.state.condogrid.currentFloor.name) {
                this.$store.commit("SET_CRUMBS", "");
            }

            this.$store.commit("changeType", item.value);
            if (item.parent == "floors") {
                this.$store.commit("CHANGE_CURRENTFLOOR", item.value);
            }
            if (item.parent == "amenities") {
                Object.values(this.floors).forEach((floor) => {
                    floor.siteplan.lots.forEach((lot) => {
                        if (lot.id == item.value) {
                            this.$store.commit("SELECT_LOT_SHAPE", lot.shapeId);
                        }
                    });
                });
            }
        },
        editFloor(floor) {
            this.floorToEdit = floor.id;
        },
        editLot(lot) {
            this.$store.commit("SHOW_EDIT_LOT", lot);
        },
        changeFloor(floor) {
            let needsEdit = this.floorToEdit === floor.id;
            if (needsEdit) this.$store.dispatch("EDIT_FLOOR", floor);
            else if (floor.id !== this.$store.state.condogrid.currentFloor) {
                this.$store.dispatch("CHANGE_FLOOR", floor);
            }

            return (this.floorToEdit = "");
        },
    },
};
</script>
